/* eslint-disable import/prefer-default-export */
import { lazy } from 'react';
import smoothScroll from 'smoothscroll';

export const goToBlock = (e, block, onClick) => {
  e.preventDefault();
  if (onClick) {
    onClick();
  }

  smoothScroll(document.querySelector(block));
};

export const ga = (...args) =>
  typeof window.ga === 'function' && window.ga(...args);

export const gaButtonClick = (buttonLabel) =>
  ga('send', {
    hitType: 'event',
    eventCategory: 'Спецпроект',
    eventAction: `Tinkoff challenge_клик на кнопку_${buttonLabel}`,
    eventLabel: '',
  });

export function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export const lazyAndRetry = (component) => lazy(() => retry(() => component));

import React, { useState, Suspense } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';
import * as Sentry from '@sentry/browser';
import { createBrowserHistory } from 'history';
import firebase from './firebase';
import { ga, lazyAndRetry } from './utils';

const MainPage = lazyAndRetry(import('./MainPage'));
const RatingPage = lazyAndRetry(import('./RatingPage'));
// const LoginPage = lazyAndRetry(import('./LoginPage'));
// const RegistrationPage = lazyAndRetry(import('./RegistrationPage'));
const QuizPage = lazyAndRetry(import('./Dashboard/Tasks/Quiz'));
const SpecialDashboardPage = lazyAndRetry(import('./Dashboard/Tasks/Special'));
const Dashboard = lazyAndRetry(import('./Dashboard'));
const Rating = lazyAndRetry(import('./Dashboard/Rating'));
const BankAuthorize = lazyAndRetry(import('./LoginPage/BankAuth/Authorize'));
const VKAuthorize = lazyAndRetry(import('./LoginPage/SocialAuth/VKAuthorize'));
const RefPage = lazyAndRetry(import('./RefPage'));

const browserHistory = createBrowserHistory();

browserHistory.listen(() => {
  ga('set', 'page', window.location.pathname);
  ga('send', 'pageview');

  ga('send', {
    hitType: 'event',
    eventCategory: 'Спецпроект',
    eventAction: 'Tinkoff challenge_просмотр страницы',
    eventLabel: window.location.href,
  });
});

const ProtectedRoute = ({ path, component: Component }) => (
  <Route
    path={path}
    exact
    render={({ history, location, match }) => (
      <Component history={history} match={match} location={location} />
    )}
  />
);

const App = () => {
  const [currentUser, setUser] = useState(null);
  const [isUserLoaded, setLoaded] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(async user => {
      setUser(user);

      const isLoggedIn = user !== null;

      function loadUser(doc) {
        const data = { id: doc.id, ...doc.data() };

        Sentry.configureScope(scope => {
          scope.setUser(data);
        });

        setUserInfo(data);
        setLoaded(true);
      }

      if (isLoggedIn) {
        const userRef = firebase
          .firestore()
          .collection('users')
          .doc(user.uid);
        const loadedUser = await userRef.get();

        loadUser(loadedUser);
        userRef.onSnapshot(loadUser);
      }

      return setLoaded(true);
    });
  }, []);

  const isLoggedIn = currentUser !== null;

  return (
    <Router history={browserHistory}>
      <Suspense fallback={<div />}>
        <ScrollMemory />

        <Switch>
          <Route
            path="/"
            exact
            render={() => (
              <MainPage isLoggedIn={isLoggedIn} user={currentUser} />
            )}
          />
          <Route
            path="/rating"
            exact
            render={() => (
              <RatingPage isLoggedIn={isLoggedIn} user={currentUser} />
            )}
          />
          {/* <Route
            path="/auth"
            exact
            render={({ history }) => (
              <LoginPage history={history} user={currentUser} />
            )}
          />
          <Route path="/register" exact component={RegistrationPage} /> */}
          <ProtectedRoute
            path="/dashboard/:challenge/special/:partner"
            isLoggedIn={isLoggedIn}
            isUserLoaded={isUserLoaded}
            userInfo={userInfo}
            component={SpecialDashboardPage}
          />
          <ProtectedRoute
            path="/dashboard/:challenge/quiz/:taskId"
            isLoggedIn={isLoggedIn}
            isUserLoaded={isUserLoaded}
            userInfo={userInfo}
            component={QuizPage}
          />
          <ProtectedRoute
            path="/dashboard/:challenge/rating"
            isLoggedIn={isLoggedIn}
            isUserLoaded={isUserLoaded}
            userInfo={userInfo}
            component={Rating}
          />
          <ProtectedRoute
            path="/dashboard/:challenge"
            isLoggedIn={isLoggedIn}
            isUserLoaded={isUserLoaded}
            userInfo={userInfo}
            component={Dashboard}
          />
          <Redirect from="/register" to="/dashboard" />
          <Redirect from="/auth" to="/dashboard" />
          <Redirect from="/dashboard" to="/dashboard/health" />
          <Route path="/authorize" exact component={BankAuthorize} />
          <Route path="/authorize/vk" exact component={VKAuthorize} />
          <Route path="/ref/:id" exact component={RefPage} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default App;

import React from 'react';
import ReactDOM from 'react-dom';
import sbjs from 'sourcebuster';
import * as Sentry from '@sentry/browser';
import 'normalize.css';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });

sbjs.init({
  domain: {
    host: process.env.REACT_APP_DOMAIN,
    isolate: true,
  },
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
